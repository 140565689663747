button, .button {
    &:not(.-link, .close-hellobars, .owl-next, .owl-prev) {
        line-height: 1 !important;
        height: 2.8125rem;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 1.875rem !important;
        padding-right: 1.875rem !important;
        transition: all .5s !important;
        margin: 0 !important;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: fit-content;

        &.-red {
            &.-bgfull {
                background: #ff352f;
                border: solid 1px #ff352f;
                color: white;

                & * {
                    color: white;
                    transition: all .5s;
                }

                &:hover {
                    background: transparent;
                    color: #ff352f;

                    & * {
                        color: #ff352f;
                    }
                }
            }
        }

        &.-black {
            &.-bgtransparent {
                color: #333;
                border: solid 1px #333;
                background: transparent;

                * {
                    transition: color .5s;
                }

                &:hover {
                    background: #333;
                    color: white;

                    * {
                        color: white;
                    }
                }
            }

            &.-bgfull {
                background: #333;
                color: white;
                border: solid 1px #333;

                * {
                    color: white;
                }

                &:hover {
                    background: transparent;
                    color: #333;

                    * {
                        color: #333;
                    }
                }
            }
        }

        &.-yellow {
            &.-bgtransparent {
                color: #222d65;
                border: #222d65;
                background: transparent;

                &:hover {
                    background: #222d65;
                }
            }
        }

        &.-white {
            &.-bgfull {
                background: white;
                border: solid 1px transparent;
                color: #333;
                border: solid 1px transparent;

                & * {
                    color: #333;
                    transition: all .5s;
                }

                &:hover {
                    background: transparent;
                    color: white;
                    border-color: white;

                    & * {
                        color: white;
                    }
                }
            }

            &.-bgtransparent {
                color: white;
                border: solid 1px white;
                background: transparent;
                transition: all .5s;

                & * {
                    color: white;
                    transition: all .5s;
                }

                &:hover {
                    background: white;
                    color: #333;

                    & * {
                        color: #333;
                    }
                }
            }
        }

        &.-fw {
            width: 100%;
        }

        &.-big {
            height: 2.75rem;
        }

        &.-center {
            margin: auto !important;
        }

        &.close-reveal-modal {
            top: inherit;
            right: inherit;
            position: inherit;
        }

        &.tc-privacy-button {
            margin: 0 .625rem !important;
        }

        &.-round {
            border-radius: 50%;
            padding: 0 !important;
            height: 2.5rem;
            width: 2.5rem;
        }

        &.-rounded {
            border-radius: 999px;
        }

        &.close-reveal-modal {
            font-size: .875rem;
        }

        &.-disabled {
            opacity: .5;
            cursor: not-allowed;
            background: black !important;
            color: white !important;
            border: solid 1px black !important;

            &:hover {
                background: black !important;
                color: white !important;
            }
        }
    }

    &.-link {
        background: none;
        padding: 0;
        margin: 0;
        transition: opacity .2s;

        &.-underline {
            text-decoration: underline;
        }

        &.-center {
            margin: auto !important;
            display: block;
        }

        &:hover {
            color: inherit;
            opacity: .7;
        }
    }

    &.-bold {
        font-weight: bold;
    }

    &.close-modal-text {
        @include main-font-general;
    }
}

.scrollToTop {
    .fs1 {
        transform: translateY(2px);

        &:before {
            font-family: "ElegantIcons";
            content: '2';
            speak: none;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            font-size: 2rem;
            color: #cecece;
        }
    }
}