﻿#newsletter-reveal-modal {
    padding: 0;
    max-width: 95%;
    width: fit-content;
    margin-left: inherit;
    min-height: inherit;
    transform: translateX(-50%);
    left: 50%;
    top: 6.25rem !important;
    position: fixed;
}

.modal-newsletter {
    background: white;
    max-width: 100%;
    width: 42.5rem;

    .modal-newsletter__close {
        height: 1.25rem;
    }

    .modal-newsletter__wrapper {
        display: flex;

        & > * {
            flex: 1;
            max-width: 21.25rem;
            width: 100%;

            &.modal-newsletter__image {
                @media all and (max-width: 40rem) {
                    display: none;
                }
            }

            &.modal-newsletter__content {
                margin: auto;

                .content__wrapper {
                    text-align: center;
                    padding: 1.25rem;

                    .content__logo {
                        margin-bottom: 1.5625rem;
                    }

                    p:not(.-error) {
                        margin-bottom: .9375rem;
                    }

                    label, p.-error {
                        text-align: left;
                    }

                    input {
                        margin-bottom: .625rem;
                    }
                }
            }
        }
    }
}
